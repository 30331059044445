import React, { useContext, useEffect } from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import VideoBanner from "../components/VideoBanner/VideoBanner"
import SectionBox from "../components/SectionBox"
import SectionSeparator from "../components/SectionHorizontalSeparator"
import Anim from "../components/Anim"
import TagBox from "../components/TagBox"
import { getImage } from "gatsby-plugin-image"
import SectionBannerImage from "../components/SectionBanner"
import GlobalContext from "../components/GlobalContext/GlobalContext"

const localText = {
  whyAsys: {
    sr: "",
    en: "<b>ASYS IS SERVING ADRIATIC YACHTING MARKET WITH EXPERTISE AND LOCAL INSIGHTS FOR DISTINGUISHED GLOBAL CLIENTELE.</b><br />Buying or chartering yacht requires understanding of actual conditions of the vessel, and we proud ourselves with in-depth local knowledge and a very thoughtful insight on yachts based in the Adriatic."
  },
  about: {
    subtitle: {
      sr: "",
      en: "Adriatic Superyacht Service is founded by superyacht captain, with more than 20 years of superyacht experience and permanently based in the Adriatic."
    },
    text: {
      sr: "",
      en: "With profound desire to develop Adriatic in a strong yachting hub, we teamed up some great people, and surrounded ourselves with mindlike honest, knowledgeable, and passionate people and companies who strive to excellence.<br /> Building a strong network of surveyors and contractors gives us an unparalleled advantage in providing 360 experiences when buying and refitting yacht in the Adriatic. "
    }
  }
}

const HomePage = ({ pageContext, location }) => {
  const { lang: pLang, metaInfo, slug, otherLang, defLang, templateData } = pageContext

  // console.log("TEMPLATE DATA: ", templateData)

  const { lang, dispatch } = useContext(GlobalContext)
  useEffect(() => {
    dispatch({ type: "changeLanguage", payload: pLang })
  }, [pLang,dispatch])

  const data = useStaticQuery(graphql`
    query {
      ismispimage:file(relativePath: { eq: "ismspkartica.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bannerfooterimage:file(relativePath: { eq: "homepagefooterbanner.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED,aspectRatio: 2.2)
        }
      }
    }
  `)
  const IsmImage = getImage(data.ismispimage)
  const BannerFooterImage = ( templateData.sections[4]?.items[0]?.imageFile?.childImageSharp.footerCENTER &&  getImage(templateData.sections[4].items[0].imageFile.childImageSharp.footerCENTER)) || getImage(data.bannerfooterimage)

  const whyAsysTitle = templateData.sections[1].items[0].locales.find(s => s.iso === lang).value
  const whyAsysSubTitle = templateData.sections[1].items[1].locales.find(s => s.iso === lang).value
  const whyAsysDescription = templateData.sections[1].items[2].locales.find(s => s.iso === lang).value

  const ismTitle = templateData.sections[2].items[0].locales.find(s => s.iso === lang).value
  const ismDescriptions = templateData.sections[2].items[1].locales.find(s => s.iso === lang).value

  const aboutTitle = templateData.sections[3].items[0].locales.find(s => s.iso === lang).value
  const aboutSubtitle = templateData.sections[3].items[1].locales.find(s => s.iso === lang).value
  const aboutDescription = templateData.sections[3].items[2].locales.find(s => s.iso === lang).value

  // console.log("VIDEO URL: ", templateData.sections[0].items[0].imageFile.publicURL)
  return (
    <Layout
      location={location}
      metaInfo={metaInfo}
      lang={lang}
      slug={slug}
      ohterLang={otherLang}
      defLang={defLang}
    >
      <VideoBanner lang={lang} title={templateData.sections[0].items[1].locales} subtitle={templateData.sections[0].items[2].locales} videoUrl={templateData.sections[0].items[0].imageFile.publicURL} />
      <SectionBox>
        <Anim>
          <SectionSeparator />
        </Anim>
        <Anim>
          <h3 className="title">{ templateData.sections[1].items[0].locales.find(t => t.iso === lang).value || 'why ASYS'}</h3>
          { whyAsysSubTitle && whyAsysDescription && <div className="text">
              <b>{whyAsysSubTitle}</b>
              <p>{whyAsysDescription}</p>
          </div> || <div dangerouslySetInnerHTML={{__html: localText.whyAsys[lang]}}  className="text" />}
        </Anim>
      </SectionBox>
      <SectionBox>
        <TagBox title={ismTitle} descriptions={ismDescriptions} image={IsmImage} />
      </SectionBox>
      <SectionBox>
        <Anim>
          <SectionSeparator />
        </Anim>
        <Anim>
          <h3 className="title">{ aboutTitle || 'About us'}</h3>
          <p className="text">
            <b>{aboutSubtitle || localText.about.subtitle[lang]}</b>
          </p>
          {aboutDescription ? <div className="text">
            {aboutDescription}
          </div> : <div dangerouslySetInnerHTML={{__html: localText.about.text[lang]}} className="text" />}
          {/*<div dangerouslySetInnerHTML={{__html: localText.about.text[lang]}} className="text" />*/}
        </Anim>
      </SectionBox>
      {/*<SectionBannerImage>*/}
      {/*<StaticImage src="../images/yachtBg.jpeg" alt="Yacht pic"/>*/}
      {/*</SectionBannerImage>*/}
      <SectionBannerImage image={BannerFooterImage} alt="Some yacht pic" />
    </Layout>
  )
}

export default HomePage
